
.App {
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Circular';
  src: url('/fonts/circular.woff2');
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica.woff');
  font-weight:400;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/HelveticaNeueLight.woff2');
  font-weight:300;
}
